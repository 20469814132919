import * as React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import Picblock from '../../components/picblock';
import * as styles from '../../components/styles/services.module.css';

export default function SDServices({ data }) {
  const picdata = data.allMarkdownRemark.nodes;

  return (
    <Layout>
      <Seo title="Services" />
      <div className={styles.impact}>
        Silent Distortion offers a variety of analytic services
        to enable you to manage your data governance projects.
      </div>
      <div className={styles.servicetable}>
        <div className={styles.servicebox}>
          <Link className={styles.serviceboxlink} to="/services/data-profiling">
            <h2>Data Profile</h2>
            <p className={styles.servicedescription}>
              Data profiling is a great place to start understanding
              the quality of your existing data.
            </p>
            <div>
              <p className={styles.crossthru}>$29</p>
              <p><bold>Now Free</bold></p>
            </div>
          </Link>
        </div>
        <div className={styles.servicebox}>
          <Link className={styles.serviceboxlink} to="/services/roi-analysis">
            <h2>ROI Analysis</h2>
            <p className={styles.servicedescription}>
              ROI Analysis shows the impact of quality on your company's important outcomes.
            </p>
            <p>Priced per Outcome Variable</p>
          </Link>
        </div>
        <div className={styles.servicebox}>
          <Link className={styles.serviceboxlink} to="/services/roi-monitoring">
            <h2>ROI Monitoring</h2>
            <p className={styles.servicedescription}>
              ROI Monitoring show the progress your data governance projects are making.
            </p>
            <p>Contact Us for information</p>
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
query servicespage {
    allMarkdownRemark(
      sort: {fields: frontmatter___sortorder, order: ASC}
      filter: {frontmatter: {page: {eq: "services"}}}
    ) {
      nodes {
        id
        frontmatter {
          page
          pictitle
          sortorder
          title
          imageside
          imageurl {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
        }
        html
      }
    }
  }
  
`;

/* TODO:
[ ] You can publish a test website with surge.sh.
[X] Make all three boxes same height and alignment.
[ ] Make call-to-action box same size as picblock
[ ] Fix the button (looks horrible)
*/
